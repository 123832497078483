// duration of page transitions. fade in takes double the time of fade out
var fadeOutTime = 150;
var fadeInTime = 400;

var fadeIn = function() {
	if (Modernizr.touchevents) {
		$j('body').css({'visibility':'visible','opacity': '1'});
	} else {
		$j('body').css({'visibility':'visible','opacity': '0'});

		// just fade in
		$j(function(){$j('body').css({'visibility':'visible','opacity': '0'}).animate({opacity: 1}, fadeInTime);});
	}
};

window.addEventListener("pageshow", fadeIn);

// page-leave-fadeout only on desktop browsers
if (!Modernizr.touchevents) {

	// actions to performe on leaving the page
	var onPageLeave = function() {
		$j('body').animate({opacity: 0}, fadeOutTime);
	};


	// catch clicks on links
	$j(document).on('click', 'a', function(event) {

		if (event.isDefaultPrevented()) {
			// if the default handler (i.e. THIS method) is prevented, do nothing.
			return false;
		}
		if ($j(this).is('.no-transition-link, .download, .simplemodal-close, a[href^="#"], a[href^="mailto"], a[href^="tel"]') || typeof $j(this).attr('target') !== 'undefined') {
			return;
		}
		if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
			return true;
		}

		if (!($j(this).attr('onClick'))) {
			const href = $j(this).attr('href');
			onPageLeave();
			setTimeout(function(){document.location = href;}, fadeOutTime + 1);
			event.preventDefault();
			return false;
		}
	});

	// catch clicks on image buttons
	let buttonClicked = false;
	$j(document).on('click', 'input[type=submit],input[type=image]', function(e){

		if ($j(this).is('.no-transition-button')) {
			return
		}

		// if already clicked do nothing
		if (buttonClicked) {
			return;
		}
		buttonClicked = true;

		const input = this;
		onPageLeave();
		setTimeout(function(){input.click();}, fadeOutTime + 1);
		e.preventDefault();
		return false;
	});

	window.pageTransitionTo = function(url) {
		onPageLeave();
		setTimeout(function(){document.location = url;}, fadeOutTime + 1);
	}

}
else {
    window.pageTransitionTo = function(url) {
        document.location = url;
    }
}
